<template>
  <div v-if="item && !loading">
    <div class="ml-5">
      <span class="font-weight-bold text-body-1" style="color: black" left> {{item.title}} </span>
    </div>
    <div class="ml-5">
      <span class="font-weight-bold text-body-2" left> {{item.answer_count.length}} respostas</span>
    </div>
    <Bar
      v-if="!loading"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>  
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    item: {
      type: Object,
      default: ()=> {}
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 280
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }
        ]
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function(val, index) {
                return index;
              },
            },
            title: {
              display: true,
              text: 'Quantidade de Respostas'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Opções de Respostas'
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    setItemAnswers(){
      return this.item.answer_count.map((i)=>{
        let r = Object.entries(i)
        this.chartData.labels.push(r[0][0])
        this.chartData.datasets[0].data.push(r[0][1])
      })
    },
    setData(){
      this.loading = true
      this.setItemAnswers()
      this.setBackgroundColor()
      this.loading = false
    },
    setBackgroundColor(){
      let r = ['250','173','137','64','25','137']
      let g = ['25','22','37','22','39','37']
      let b = ['239','222','245','222','250','245']
      let aux = Math.round(Math.random() * 5)
      let opacity = 1
      this.chartData.datasets[0].backgroundColor = 'rgba('+r[aux]+','+g[aux]+','+b[aux]+', '+opacity+')'
    }
  },
  computed: {
    // answers_number(){
    //   return this.item.answer_count.length
    // }
  },
  mounted () {
    this.setData()
  },
}
</script>